import classes from "./Banner.module.css";
const Banner = () => {
  return (
    <div className={classes["banner-container"]}>
      <span className={classes["banner-text"]}>
        Infinity Games Enters The Private Sale Stage
      </span>
      <button className={classes["banner-btn"]}>
        <a
          href=" https://medium.com/@InfinityGamesTech/infinity-games-enters-the-private-sale-stage-6f0fe8448658"
          target="_blank"
          rel="noreferrer"
          className={`${classes["hero-btns-links"]} ${classes["play-link"]}`}
        >
          Find Out More
        </a>
      </button>
    </div>
  );
};

export default Banner;
