import classes from "./VideoSection.module.css";
import React, { useState, useEffect } from "react";

const InfinityHeroesVideo = () => {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const button = document.getElementById("download-btn");

    const stopAnimation = () => {
      setIsAnimating(false);
      button.removeEventListener("click", stopAnimation);
    };

    if (isAnimating) {
      button.addEventListener("click", stopAnimation);
    }

    return () => {
      button.removeEventListener("click", stopAnimation);
    };
  }, [isAnimating]);

  return (
    <div className={classes["video-wrapper"]}>
      <div className={classes["video-content"]}>
        <div className={classes["video-main-content"]}>
          <iframe
            className={classes["video-iframe"]}
            src="https://www.youtube.com/embed/xsicoPIsX_k?si=mXduaI2nb9cZP3Ib"
            title="YouTube video player"
            // frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
          ></iframe>
        </div>
        <a
          id="download-btn"
          href="https://infinitygame.s3.eu-central-1.amazonaws.com/Infinity_Heroes_Launcher.exe"
          target="_self"
          rel="noreferrer"
          //   className={classes["download-btn"]}
          className={`${classes["download-btn"]} ${
            isAnimating ? classes.buttonAnimating : ""
          }`}
        >
          <span>Download Game</span>
        </a>
      </div>
    </div>
  );
};

export default InfinityHeroesVideo;
