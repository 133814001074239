import Encrypted from "../../assets/carousel/encrypted.png";
import Ethernal from "../../assets/carousel/ethernal.png";
import Harmonity from "../../assets/carousel/harmonity.png";
import InovacioniFond from "../../assets/carousel/inovacioni-fond.png";
import NameYourDemon from "../../assets/carousel/name-your-demon.png";
import Blackpool from "../../assets/carousel/blackpool.png";
import Immutable from "../../assets/carousel/immutable.png";
import PlanX from "../../assets/carousel/planx.png";
import Sphere from "../../assets/carousel/sphere.png";
import GG3 from "../../assets/carousel/gg3.png";
import Momentum6 from "../../assets/carousel/momentum6.png";
import Symbiote from "../../assets/carousel/symbiote.png";
import Onstack from "../../assets/carousel/onstack.png";
import Zesh from "../../assets/carousel/zesh.png";
import Elixir from "../../assets/carousel/elixir.png";
import Ggem from "../../assets/carousel/ggem.svg";
import classes from "./HeroCarousel.module.css";

import ReusableCarouselItem from "./ReusableCarouselItem";

const HeroCarousel = () => {
  const cardDetails = {
    0: {
      imgUrl: Encrypted,
      title: "Partner 1",
      link: "https://encrypted-gods.io/",
    },

    1: {
      imgUrl: Ethernal,
      title: "Partner 2",
      link: "https://ethernal.tech/",
    },

    2: {
      imgUrl: Harmonity,
      title: "Partner 3",
      link: "https://harmonity.com/",
    },

    3: {
      imgUrl: InovacioniFond,
      title: "Partner 4",
      link: "https://www.inovacionifond.rs/en/fond/about-fund",
    },

    4: {
      imgUrl: NameYourDemon,
      title: "Partner 5",
      link: "https://nameyourdemon.com/",
    },

    5: {
      imgUrl: Blackpool,
      title: "Partner 6",
      link: "https://blackpool.finance/",
    },

    6: {
      imgUrl: Immutable,
      title: "Partner 7",
      link: "https://www.immutable.com/",
    },

    7: {
      imgUrl: PlanX,
      title: "PlanX",
      link: "https://planx.io/",
    },
    8: {
      imgUrl: Sphere,
      title: "Sphere",
      link: "https://sphere.market/beam",
    },
    9: {
      imgUrl: GG3,
      title: "GG3",
      link: "https://gg3.xyz/",
    },
    10: {
      imgUrl: Momentum6,
      title: "Momentum6",
      link: "https://momentum6.com/",
    },
    11: {
      imgUrl: Symbiote,
      title: "Symbiote",
      link: "https://symbiote.gg/",
    },
    12: {
      imgUrl: Onstack,
      title: "Onstack",
      link: null,
    },
    13: {
      imgUrl: Zesh,
      title: "Zesh",
      link: "https://zesh.io/",
    },
    14: {
      imgUrl: Elixir,
      title: "Elixir",
      link: "https://elixir.games/",
    },
    15: {
      imgUrl: Ggem,
      title: "Ggem",
      link: "https://ggem.gg/",
    },
  };

  return (
    <div className={classes["hero-carousel-viewport"]}>
      <div className={classes["hero-carousel"]}>
        <div className={classes["hero-carousel-container"]}>
          <div className={classes["hero-carousel-track"]}>
            {Object.keys(cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={cardDetails[detailKey].link}
                  imgUrl={cardDetails[detailKey].imgUrl}
                  imgTitle={cardDetails[detailKey].title}
                  className={classes["hero-carousel-card"]}
                ></ReusableCarouselItem>
              );
            })}
          </div>
          <div className={classes["hero-carousel-track-two"]}>
            {Object.keys(cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={cardDetails[detailKey].link}
                  imgUrl={cardDetails[detailKey].imgUrl}
                  imgTitle={cardDetails[detailKey].title}
                  className={classes["hero-carousel-card"]}
                ></ReusableCarouselItem>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCarousel;
