import classes from "./HeroesSection.module.css";
import Button from "../UI/Button";
import LayerOne from "../../assets/parallax/layer-one.png";
import LayerTwo from "../../assets/parallax/layer-two.png";
import LayerThree from "../../assets/parallax/layer-three.png";
import LayerFour from "../../assets/parallax/layer-four.png";
import { useParallax } from "react-scroll-parallax";

const HeroesSection = () => {
  const layer1 = useParallax({ scale: [0.7, 1] });
  const layer2 = useParallax({ scale: [1, 1.3] });
  const layer3 = useParallax({ speed: 10 });
  const layer4 = useParallax({ speed: 2 });

  return (
    <div className={classes["heroes-section"]}>
      <div className={classes["heroes-container"]}>
        <div className={classes["heroes-content"]}>
          <div className={classes["heroes-text"]}>
            <h1>Infinity heroes</h1>
            <p>
              The first game in our ecosystem and one of the most advanced
              blockchain auto-battlers.
            </p>
            <Button
              className={classes["heroes-button"]}
              href={"https://infinitygames.tech/infinity_heroes"}
              text={"Find Out More"}
            />
          </div>
          <div className={classes["heroes-parallax"]}>
            <img
              ref={layer1.ref}
              src={LayerOne}
              className={`${classes.layer} ${classes["layer-one"]}`}
            />
            <img
              ref={layer2.ref}
              src={LayerTwo}
              className={`${classes.layer} ${classes["layer-two"]}`}
            />
            <img
              ref={layer3.ref}
              src={LayerThree}
              className={`${classes.layer} ${classes["layer-three"]}`}
            />
            <img
              ref={layer4.ref}
              src={LayerFour}
              className={`${classes.layer} ${classes["layer-four"]}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroesSection;
